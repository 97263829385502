var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from 'react';
import classNames from 'classnames';
import RCPicker, { RangePicker as RCRangePicker } from 'rc-picker';
import { CalendarOutlined, ClockCircleOutlined, CloseCircleFilled } from '@ant-design/icons';
import { ConfigContext } from '../config-provider';
import LocaleReceiver from '../locale-provider/LocaleReceiver';
import enUS from './locale/en_US';
import { getPlaceholder, getRangePlaceholder } from './util';
import PickerButton from './PickerButton';
import PickerTag from './PickerTag';
import SizeContext from '../config-provider/SizeContext';
const Components = { button: PickerButton, rangeItem: PickerTag };
function toArray(list) {
    if (!list) {
        return [];
    }
    return Array.isArray(list) ? list : [list];
}
function getTimeProps(props) {
    const { format, picker, showHour, showMinute, showSecond, use12Hours } = props;
    const firstFormat = toArray(format)[0];
    const showTimeObj = Object.assign({}, props);
    if (firstFormat) {
        if (!firstFormat.includes('s') && showSecond === undefined) {
            showTimeObj.showSecond = false;
        }
        if (!firstFormat.includes('m') && showMinute === undefined) {
            showTimeObj.showMinute = false;
        }
        if (!firstFormat.includes('H') && !firstFormat.includes('h') && showHour === undefined) {
            showTimeObj.showHour = false;
        }
        if ((firstFormat.includes('a') || firstFormat.includes('A')) && use12Hours === undefined) {
            showTimeObj.use12Hours = true;
        }
    }
    if (picker === 'time') {
        return showTimeObj;
    }
    return {
        showTime: showTimeObj,
    };
}
function generatePicker(generateConfig) {
    function getPicker(picker, displayName) {
        class Picker extends React.Component {
            constructor() {
                super(...arguments);
                this.pickerRef = React.createRef();
                this.focus = () => {
                    if (this.pickerRef.current) {
                        this.pickerRef.current.focus();
                    }
                };
                this.blur = () => {
                    if (this.pickerRef.current) {
                        this.pickerRef.current.blur();
                    }
                };
                this.getDefaultLocale = () => {
                    const { locale } = this.props;
                    const result = Object.assign(Object.assign({}, enUS), locale);
                    result.lang = Object.assign(Object.assign({}, result.lang), (locale || {}).lang);
                    return result;
                };
                this.renderPicker = (locale) => {
                    const { getPrefixCls, direction } = this.context;
                    const _a = this.props, { prefixCls: customizePrefixCls, className, size: customizeSize, bordered = true } = _a, restProps = __rest(_a, ["prefixCls", "className", "size", "bordered"]);
                    const { format, showTime } = this.props;
                    const prefixCls = getPrefixCls('picker', customizePrefixCls);
                    const additionalProps = {
                        showToday: true,
                    };
                    let additionalOverrideProps = {};
                    if (picker) {
                        additionalOverrideProps.picker = picker;
                    }
                    const mergedPicker = picker || this.props.picker;
                    additionalOverrideProps = Object.assign(Object.assign(Object.assign({}, additionalOverrideProps), (showTime ? getTimeProps(Object.assign({ format, picker: mergedPicker }, showTime)) : {})), (mergedPicker === 'time'
                        ? getTimeProps(Object.assign(Object.assign({ format }, this.props), { picker: mergedPicker }))
                        : {}));
                    return (<SizeContext.Consumer>
            {size => {
                        const mergedSize = customizeSize || size;
                        return (<RCPicker ref={this.pickerRef} placeholder={getPlaceholder(mergedPicker, locale)} suffixIcon={mergedPicker === 'time' ? <ClockCircleOutlined /> : <CalendarOutlined />} clearIcon={<CloseCircleFilled />} allowClear transitionName="slide-up" {...additionalProps} {...restProps} {...additionalOverrideProps} locale={locale.lang} className={classNames(className, {
                            [`${prefixCls}-${mergedSize}`]: mergedSize,
                            [`${prefixCls}-borderless`]: !bordered,
                        })} prefixCls={prefixCls} generateConfig={generateConfig} prevIcon={<span className={`${prefixCls}-prev-icon`}/>} nextIcon={<span className={`${prefixCls}-next-icon`}/>} superPrevIcon={<span className={`${prefixCls}-super-prev-icon`}/>} superNextIcon={<span className={`${prefixCls}-super-next-icon`}/>} components={Components} direction={direction}/>);
                    }}
          </SizeContext.Consumer>);
                };
            }
            render() {
                return (<LocaleReceiver componentName="DatePicker" defaultLocale={this.getDefaultLocale}>
            {this.renderPicker}
          </LocaleReceiver>);
            }
        }
        Picker.contextType = ConfigContext;
        if (displayName) {
            Picker.displayName = displayName;
        }
        return Picker;
    }
    const DatePicker = getPicker();
    const WeekPicker = getPicker('week', 'WeekPicker');
    const MonthPicker = getPicker('month', 'MonthPicker');
    const YearPicker = getPicker('year', 'YearPicker');
    const TimePicker = getPicker('time', 'TimePicker');
    // ======================== Range Picker ========================
    class RangePicker extends React.Component {
        constructor() {
            super(...arguments);
            this.pickerRef = React.createRef();
            this.focus = () => {
                if (this.pickerRef.current) {
                    this.pickerRef.current.focus();
                }
            };
            this.blur = () => {
                if (this.pickerRef.current) {
                    this.pickerRef.current.blur();
                }
            };
            this.getDefaultLocale = () => {
                const { locale } = this.props;
                const result = Object.assign(Object.assign({}, enUS), locale);
                result.lang = Object.assign(Object.assign({}, result.lang), (locale || {}).lang);
                return result;
            };
            this.renderPicker = (locale) => {
                const { getPrefixCls, direction } = this.context;
                const _a = this.props, { prefixCls: customizePrefixCls, className, size, bordered = true } = _a, restProps = __rest(_a, ["prefixCls", "className", "size", "bordered"]);
                const { format, showTime, picker } = this.props;
                const prefixCls = getPrefixCls('picker', customizePrefixCls);
                let additionalOverrideProps = {};
                additionalOverrideProps = Object.assign(Object.assign(Object.assign({}, additionalOverrideProps), (showTime ? getTimeProps(Object.assign({ format, picker }, showTime)) : {})), (picker === 'time' ? getTimeProps(Object.assign(Object.assign({ format }, this.props), { picker })) : {}));
                return (<RCRangePicker separator={<span className={`${prefixCls}-separator`}>→</span>} ref={this.pickerRef} placeholder={getRangePlaceholder(picker, locale)} suffixIcon={picker === 'time' ? <ClockCircleOutlined /> : <CalendarOutlined />} clearIcon={<CloseCircleFilled />} allowClear transitionName="slide-up" {...restProps} className={classNames(className, {
                    [`${prefixCls}-${size}`]: size,
                    [`${prefixCls}-borderless`]: !bordered,
                })} {...additionalOverrideProps} locale={locale.lang} prefixCls={prefixCls} generateConfig={generateConfig} prevIcon={<span className={`${prefixCls}-prev-icon`}/>} nextIcon={<span className={`${prefixCls}-next-icon`}/>} superPrevIcon={<span className={`${prefixCls}-super-prev-icon`}/>} superNextIcon={<span className={`${prefixCls}-super-next-icon`}/>} components={Components} direction={direction}/>);
            };
        }
        render() {
            return (<LocaleReceiver componentName="DatePicker" defaultLocale={this.getDefaultLocale}>
          {this.renderPicker}
        </LocaleReceiver>);
        }
    }
    RangePicker.contextType = ConfigContext;
    const MergedDatePicker = DatePicker;
    MergedDatePicker.WeekPicker = WeekPicker;
    MergedDatePicker.MonthPicker = MonthPicker;
    MergedDatePicker.YearPicker = YearPicker;
    MergedDatePicker.RangePicker = RangePicker;
    MergedDatePicker.TimePicker = TimePicker;
    return MergedDatePicker;
}
export default generatePicker;
