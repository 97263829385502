import * as React from "react";
import { Field, FormikProps } from "formik";
import { FormConstants } from "./Constants";
import Checkbox from 'formik-antd/es/checkbox';
import Radio from "formik-antd/es/radio";
import Select from "formik-antd/es/select"
import DatePicker from "formik-antd/es/date-picker"
import TimePicker from "formik-antd/es/time-picker"
import "formik-antd/es/checkbox/style/css"
import "formik-antd/es/radio/style/css";
import "formik-antd/es/select/style/css"
import "formik-antd/es/date-picker/style/css"

import { FormikErrors, FormikTouched } from "formik";
import { IFieldProps } from "./FormFields";
import { removeWhiteSpaces } from "./FunctionLib";
import { isNullOrWhiteSpace } from "../../../common/helper/helper";


const CheckboxGroup = Checkbox.Group
const RadioGroup = Radio.Group
const {Option} = Select;

interface IFormFieldProps {
    name: string;
    className?: string;
    fieldClassName: string
    placeholder: string;
    component?: string | JSX.Element;
    errors: any;
    touched: any;
    children?: string | JSX.Element | JSX.Element[];
    colorTheme: string;
    onChange?:(event:React.FormEvent<HTMLSelectElement>) =>void;
    type?: string;
    isLastField?: boolean;
    maxLength?: number;
    isColumn: boolean;
    setFieldValue:(field: string, value: string| string[]|number)=>void
}

const FormField = (props: IFormFieldProps) => {

    const getClassName = () => {
        if (props.isColumn && props.name != FormConstants.numberOfTickets) {
            return "col-md-12 my-1 px-4"
        } else if (props.isLastField) {
            return "col-md-12 my-2 px-1"
        } else {
            if (props.name == FormConstants.numberOfTickets){
                return "col-md-12 num-of-ticket"
            }
            else {
                return "col-md-6 my-2 px-1"
            }
        }
    }
    const getFieldClassName = (props: IFormFieldProps) => {
        let fieldClassName = `${props.fieldClassName}`;
        if (props.colorTheme === "transparantGrey") {
            fieldClassName += " transparantGreyBoxShadow"
        }
        if (props.colorTheme === "transparantGrey" && props.component !== "textarea") {
            fieldClassName += " transparantGreyField";
        }
        if (props.colorTheme === "transparantGrey" && props.component === "textarea") {
            fieldClassName += " transparantGreyFieldTextArea";
        }
        if (props.isLastField && props.component === "textarea") {
            fieldClassName += " col-md-12 inputPadding";
        }
        if (props.errors[props.name] && props.touched[props.name]) {
            fieldClassName += ` error-validation-${props.colorTheme}`;
        } else {
            fieldClassName += " genFieldBorder";
        }
        return fieldClassName;
    }

    return (
        <div key={props.name} className={getClassName()} >
            <Field              
                onChange={(event: React.FormEvent<HTMLSelectElement>)=>{
                    if(props.onChange){
                        props.onChange(event);
                    }
                    props.setFieldValue(props.name, event.currentTarget.value)}}
                className={getFieldClassName(props)}
                type={props.type}
                name={props.name}
                placeholder={props.placeholder}
                component={props.component}
                maxLength={props.maxLength}
            >
                {props.children}
            </Field>
        </div>
    );
}

const CustomFieldWrapper = (props: React.PropsWithChildren<ICustomFieldWrapperProps>) => {
    const { fieldProps, errors, touched, isColumn, className, labelClassName } = props
    return <div className={`${className} ${getCustomFieldSpacingClassName(fieldProps, errors, touched, isColumn)}`}>
        <p className={`custom-field-label ${labelClassName}`}>{!isNullOrWhiteSpace(fieldProps.Label) ? fieldProps.Label : fieldProps.Name}{fieldProps.Required ? "*" : ""}</p>
        {props.children}
    </div>
}

const getCustomFieldSpacingClassName = (fieldProps: IFieldProps, errors: FormikErrors<any>, touched: FormikTouched<any>, isColumn: boolean) => {
    let className = "my-2 "
    className += isColumn ? "px-4 " : "px-1 ";
    className += fieldProps.IsFullWidth || isColumn ? "col-md-12 " : "col-md-6 "
    className += touched[fieldProps.Name] && errors[fieldProps.Name] ? "error" : ""
    return className
}

export const CustomFormFieldFactory = (fieldProps: IFieldProps, setFieldValue: any, isLastField: boolean, isColumn: boolean, key: string | number, errors: FormikErrors<any>, touched: FormikTouched<any>, colorTheme: string) => {
    switch (fieldProps.CustomFieldType) {
        case CustomFieldType.Checkbox:
            return <div key={key} className={`checkbox-group-wrapper ${getCustomFieldSpacingClassName(fieldProps, errors, touched, isColumn)}`}>
                <Checkbox name={fieldProps.Name}>{!isNullOrWhiteSpace(fieldProps.Label) ? fieldProps.Label : fieldProps.Name}{fieldProps.Required ? "*" : ""}</Checkbox>
            </div>

        case CustomFieldType.CheckboxGroup:
            return <CustomFieldWrapper className="checkbox-group-wrapper" key={key} fieldProps={fieldProps} touched={touched} errors={errors} isColumn={isColumn}>
                <CheckboxGroup name={fieldProps.Name} options={fieldProps.Options} />
            </CustomFieldWrapper>
        
        case CustomFieldType.RadioButtonGroup:
            return <CustomFieldWrapper className="radio-group-wrapper" key={key} fieldProps={fieldProps} touched={touched} errors={errors} isColumn={isColumn}>
                <RadioGroup name={fieldProps.Name} options={fieldProps.Options} />
            </CustomFieldWrapper>
        
        case CustomFieldType.DropDown:
            return <Select key={key} name={fieldProps.Name}
                dropdownClassName="custom-select-dropdown"
                placeholder={`${!isNullOrWhiteSpace(fieldProps.Label) ? fieldProps.Label : `Please select the ${fieldProps.Name}`} ${fieldProps.Required ? "*" : ""}`}
                className={`custom-select-field ${getCustomFieldSpacingClassName(fieldProps, errors, touched, isColumn)}`}>
                {fieldProps.Options && fieldProps.Options.length > 0 && fieldProps.Options.map((option, i) =>
                    <Option key={i} value={option}>{option}</Option>)}
            </Select>

        case CustomFieldType.DatePicker:
            return <CustomFieldWrapper className="date-time-picker-wrapper d-flex" labelClassName="col-sm-6" key={key} fieldProps={fieldProps} touched={touched} errors={errors} isColumn={isColumn}>
                <DatePicker className="col-sm-6" format='DD/MM/YYYY' name={fieldProps.Name} />
            </CustomFieldWrapper>

        case CustomFieldType.TimePicker:
            return <CustomFieldWrapper className="date-time-picker-wrapper d-flex" labelClassName="col-sm-6" key={key} fieldProps={fieldProps} touched={touched} errors={errors} isColumn={isColumn}>
                <TimePicker className="col-sm-6" format='hh:mm a' name={fieldProps.Name} />
            </CustomFieldWrapper>

        case CustomFieldType.Text:
        default:
            return <FormField
            setFieldValue={setFieldValue}
            isLastField={isLastField || fieldProps.IsFullWidth}
            isColumn={isColumn || fieldProps.IsFullWidth}
            key={key}
            fieldClassName="form-control"
            name={removeWhiteSpaces(
                fieldProps.Name
            )}
            placeholder={
                fieldProps.Required
                    ? fieldProps.Placeholder +
                    "*"
                    : fieldProps.Placeholder
            }
            component={
                fieldProps.Textarea
                    ? "textarea"
                    : undefined
            }
            errors={errors}
            touched={touched}
            colorTheme={colorTheme}
        />
    }
}

export enum CustomFieldType {
    Text = "text",
    TextArea = "textArea",
    Checkbox = "checkbox",
    CheckboxGroup = "checkboxGroup",
    RadioButtonGroup = "radioButtonGroup",
    DropDown = "dropdown",
    DatePicker = "datePicker",
    TimePicker = "timePicker"
}

interface ICustomFieldWrapperProps {
    className?: string
    fieldProps: IFieldProps
    errors: FormikErrors<any>
    touched: FormikTouched<any>
    key: string | number
    isColumn: boolean
    labelClassName?: string
}

export default FormField

