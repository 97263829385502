import * as React from "react";
import { Modal } from 'react-bootstrap';
import {
    FacebookShareButton,
    FacebookIcon,
    TwitterShareButton,
    TwitterIcon,
    WhatsappShareButton,
    WhatsappIcon,
    EmailShareButton,
    EmailIcon,
    LinkedinShareButton,
    LinkedinIcon,
    RedditShareButton,
    RedditIcon
} from "react-share";

interface IModalProps {
    showModal: boolean;
    setShowModal: any;
    shareUrl: string;
    shareTitle: string;
    shareMessage: string;
    shareHashTag: string;
    modalTitle: string;
    modalMessage: string;
    colorTheme: string;
    modalImageUrl: string;
}

export default (props: IModalProps) => {
    let shareUrl = props.shareUrl !== "" ? props.shareUrl : location.href;
    return (
        <Modal
            show={props.showModal}
            onHide={() => props.setShowModal(false)}
            centered
            size="xl"
            className="container"
        >
            <Modal.Header>
                <Modal.Title className="genModalHeader">{props.modalTitle ? props.modalTitle : "Thank you."}</Modal.Title>
            </Modal.Header>

            <Modal.Body className={props.colorTheme} >
                <p className="genModalMessage">{props.modalMessage}</p>
            </Modal.Body>
            <Modal.Body className="container-fluid p-0">
                <img className="img-fluid" src={props.modalImageUrl}></img>
            </Modal.Body>
            <Modal.Footer className="justify-content-center genModalFooter">
                <div className="row">
                    <p className="genModalSocialMediaMessage">
                        Click an icon below to share this online.
                    </p>
                </div>
                <div className="row">
                    <div className="genShareButton">
                        <FacebookShareButton
                            url={shareUrl}
                            quote={props.shareMessage}
                            hashtag={props.shareHashTag}
                        >
                            <FacebookIcon round size={30} />
                        </FacebookShareButton>
                    </div>
                    <div className="genShareButton">
                        <TwitterShareButton
                            url={shareUrl}
                            hashtags={[props.shareHashTag]}
                        >
                            <TwitterIcon round size={30} />
                        </TwitterShareButton>
                    </div>
                    <div className="genShareButton">
                        <WhatsappShareButton
                            url={shareUrl}
                            title={props.shareTitle}
                        >
                            <WhatsappIcon round size={30} />
                        </WhatsappShareButton>
                    </div>
                    <div className="donShareButton">
                        <LinkedinShareButton
                            url={shareUrl}
                        >
                            <LinkedinIcon round size={30} />
                        </LinkedinShareButton>
                    </div>
                    <div className="donShareButton">
                        <RedditShareButton
                            url={shareUrl}
                            title={props.shareTitle}
                        >
                            <RedditIcon round size={30} />
                        </RedditShareButton>
                    </div>
                    <div className="genShareButton">
                        <EmailShareButton
                            url={shareUrl}
                            subject={props.shareTitle}
                            body={props.shareMessage}
                        >
                            <EmailIcon round size={30} />
                        </EmailShareButton>
                    </div>
                </div>
            </Modal.Footer>
        </Modal>
    );
}