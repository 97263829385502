import * as Yup from 'yup';
import { Shape, StringSchema } from 'yup';
import { unCamelCase } from '../../../common/helper/helper';
import { GenericFormModel } from '../../ml_modules/websiteApi/GenericFormModel';
import { FormConstants, ICommonFieldsMeta, WaFormConstants } from './Constants';
import { CustomFieldType } from './FormField';
import { IFieldProps } from './FormFields';

export const removeWhiteSpaces = (value: string) => {
	return value.replace(/ /g, '');
};

export const formatNumber = (value: string) => {
	return value.replace(/[$|£,]/g, '');
};

export const formFieldRex = {
  postcodeRegExp: /(\b(to_replace)\b|^(?:(?:[2-8]\d|9[0-7]|0?[28]|0?9(?=09))(?:\d{2}))$)/i,
  nameRegExp: /(\b(to_replace)\b|^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$)/i,
  noNumbersRegExp: /(\b(to_replace)\b|^([^0-9]*)$)/i,
  phoneRegExp: /(\b(to_replace)\b|^[+]?(?=(?:[^\dx]*\d){7})(?:\(\d+(?:\.\d+)?\)|\d+(?:\.\d+)?)(?:[ -]?(?:\(\d+(?:\.\d+)?\)|\d+(?:\.\d+)?))*(?:[ ]?(?:x|ext)\.?[ ]?\d{1,5})?$)/i,
  dateRegExp: /^([0-2][0-9]|(3)[0-1])(\/)(((0)[0-9])|((1)[0-2]))(\/)\d{4}$/i,
  replace: /(\b(to_replace)\b|^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$)/i
}

export const fieldErrorMessage = {
	nameFieldError: 'cannot contain any invalid characters',
	phoneFieldError: 'Please provide a valid phone number',
	noNumError: 'cannot contain any numbers',
	emailError: 'Please provide a valid email address',
	postcodeError: 'Invalid post code'
};

type FieldNames = keyof typeof FormConstants;

const getRequiredProvisionMsg = (fieldLabel: string) => `Please provide your ${unCamelCase(fieldLabel, true)}.`;

const createRequiredtextFieldSchema = (schema: StringSchema, flag: boolean, msg?: string) => {
	return flag ? schema.required(msg) : schema;
};

export const createGenericValidationSchema = (commonFieldsMeta: ICommonFieldsMeta, fields: IFieldProps[], noRegexFor?: [FieldNames]) => {
	let validationSchema = Yup.object().shape({
		[FormConstants.firstName]: createRequiredtextFieldSchema(
			Yup.string()
				.matches(formFieldRex.nameRegExp, 'First name ' + fieldErrorMessage.nameFieldError)
				.max(30),
			commonFieldsMeta.FirstName.Required,
			getRequiredProvisionMsg(FormConstants.firstName)
		),
		[FormConstants.lastName]: createRequiredtextFieldSchema(
			Yup.string()
				.matches(formFieldRex.nameRegExp, 'Last name ' + fieldErrorMessage.nameFieldError)
				.max(30),
			commonFieldsMeta.LastName.Required,
			getRequiredProvisionMsg(FormConstants.lastName)
		),
		[FormConstants.email]: createRequiredtextFieldSchema(
			Yup.string().email(fieldErrorMessage.emailError),
			commonFieldsMeta.Email.Required,
			getRequiredProvisionMsg(FormConstants.email)
		),
		[FormConstants.postcode]: createRequiredtextFieldSchema(
			Yup.string()
				.when(['postcode'], {
					is: () => noRegexFor && noRegexFor.indexOf('postcode') > -1,
					then: Yup.string().max(40)
				})
				.when(['postcode'], {
					is: () => !noRegexFor || noRegexFor.indexOf('postcode') < 0,
					then: Yup.string().matches(formFieldRex.postcodeRegExp, fieldErrorMessage.postcodeError).max(4)
				}),
			commonFieldsMeta.Postcode.Required,
			getRequiredProvisionMsg(FormConstants.postcode)
		)
	});

	if (commonFieldsMeta.State.Required && commonFieldsMeta.State.Show) {
		validationSchema = validationSchema.shape({
			[FormConstants.state]: Yup.string().required('Please select your state')
		});
	}
	fields.forEach((field: IFieldProps) => {
		if (field.Required) {
			if (field.Type !== 'Email address' && field.Type !== 'Phone number') {
				validationSchema = AddFieldValidation(field, validationSchema) as Yup.ObjectSchema<Yup.Shape<object, { [x: string]: any }>>;
			}
		}
		if (field.Type === 'Email address') {
			if (field.Required) {
				validationSchema = validationSchema.shape({
					[removeWhiteSpaces(field.Name)]: Yup.string().email(fieldErrorMessage.emailError).required(`${field.Name} required`)
				});
			} else {
				validationSchema = validationSchema.shape({
					[removeWhiteSpaces(field.Name)]: Yup.string().email(fieldErrorMessage.emailError)
				});
			}
		}
		if (field.Type === 'Phone number') {
			if (field.Required) {
				validationSchema = validationSchema.shape({
					[removeWhiteSpaces(field.Name)]: Yup.string()
						.matches(formFieldRex.phoneRegExp, fieldErrorMessage.phoneFieldError)
						.required(`${field.Name} required`)
				});
			} else {
				validationSchema = validationSchema.shape({
					[removeWhiteSpaces(field.Name)]: Yup.string().matches(formFieldRex.phoneRegExp, fieldErrorMessage.phoneFieldError)
				});
			}
		}
	});
	return validationSchema;
};

const AddFieldValidation = (field: IFieldProps, validationSchema: Yup.ObjectSchema<Shape<object, any>>) => {
	switch (field.CustomFieldType) {
		case CustomFieldType.Text:
		case CustomFieldType.TextArea:
			return validationSchema.shape({
				[removeWhiteSpaces(field.Name)]: Yup.string().required(`${field.Name} required`)
			});
		case CustomFieldType.CheckboxGroup:
			return validationSchema.shape({
				[removeWhiteSpaces(field.Name)]: Yup.array().required(`At least one checkbox of ${field.Name} field is required`)
			});
		case CustomFieldType.Checkbox:
			return validationSchema.shape({
				[removeWhiteSpaces(field.Name)]: Yup.bool().oneOf([true], `Must check ${field.Name} field.`)
			});
		default:
			return validationSchema.shape({
				[removeWhiteSpaces(field.Name)]: Yup.string().required(`${field.Name} required`)
			});
	}
};

export const createDonateFormValidationSchema = (
	donationDeclaration: boolean,
	enableCountryField: boolean,
	findDonorByEmail: boolean,
	customFields?: IFieldProps[],
	noRegexFor?: [FieldNames]
): Yup.ObjectSchema<{ [x: string]: unknown }> => {
	let validationSchema = Yup.object().shape({
		[FormConstants.amount]: Yup.number().min(1).max(1000000000).required('Please provide an amount.'),
		[FormConstants.firstName]: Yup.string().when([WaFormConstants.entityType], value => {
			if (value === 'company') {
				return Yup.string().optional();
			} else {
				return Yup.string()
					.required('Please provide your first name')
					.matches(formFieldRex.nameRegExp, 'First name ' + fieldErrorMessage.nameFieldError)
					.max(30);
			}
		}),
		[FormConstants.lastName]: Yup.string().when([WaFormConstants.entityType], value => {
			if (value === 'company') {
				return Yup.string().optional();
			} else {
				return Yup.string()
					.required('Please provide your last name')
					.matches(formFieldRex.nameRegExp, 'Last name ' + fieldErrorMessage.nameFieldError)
					.max(30);
			}
		}),
		[FormConstants.address]: Yup.string().when([WaFormConstants.entityType], value => {
			if (value === 'company') {
				return Yup.string().optional();
			} else {
				return Yup.string().required('Please provide your address').max(40);
			}
		}),
		[FormConstants.suburb]: Yup.string().when([WaFormConstants.entityType], value => {
			if (value === 'company') {
				return Yup.string().optional();
			} else {
				return Yup.string()
					.required('Please provide your suburb')
					.matches(formFieldRex.noNumbersRegExp, 'Suburb ' + fieldErrorMessage.noNumError)
					.max(40);
			}
		}),
		[FormConstants.postcode]: Yup.string().when([WaFormConstants.entityType], value => {
			if (value === 'company') {
				return Yup.string().optional();
			} else {
				return Yup.string()
					.required('Please provide your post code')
					.when(['postcode'], {
						is: () => noRegexFor && noRegexFor.indexOf('postcode') > -1,
						then: Yup.string().max(40)
					})
					.when(['postcode'], {
						is: () => !noRegexFor || noRegexFor.indexOf('postcode') < 0,
						then: Yup.string()
							.matches(formFieldRex.postcodeRegExp, fieldErrorMessage.postcodeError)
							.max(findDonorByEmail ? 10 : 4)
					});
			}
		}),
		[FormConstants.phone]: Yup.string().when([WaFormConstants.entityType], value => {
			if (value === 'company') {
				return Yup.string().optional();
			} else {
				return Yup.string()
					.required('Please provide your phone number')
					.matches(formFieldRex.phoneRegExp, fieldErrorMessage.phoneFieldError)
					.max(40);
			}
		}),
		[FormConstants.email]: Yup.string().when([WaFormConstants.entityType], value => {
			if (value === 'company') {
				return Yup.string().optional();
			} else {
				return Yup.string().required('Please provide your email address').email(fieldErrorMessage.emailError).max(40);
			}
		}),
		[FormConstants.interval]: Yup.string().required('please select: One-Off, Weekly or Monthly'),
		[FormConstants.state]: Yup.string().when([WaFormConstants.entityType], value => {
			if (value === 'company') {
				return Yup.string().optional();
			} else {
				return Yup.string().required('Please select your state');
			}
		}),
		[FormConstants.country]: Yup.string().when([WaFormConstants.entityType], value => {
			if (value === 'company') {
				return Yup.string().optional();
			} else {
				return Yup.string().when(['country'], {
					is: () => enableCountryField == true,
					then: Yup.string().required('Please select your country')
				});
			}
		}),
		[WaFormConstants.companyName]: Yup.string().when([WaFormConstants.entityType], value => {
			if (value === 'company') {
				return Yup.string().required('Name is required');
			} else {
				Yup.string().optional();
			}
		}),
		[WaFormConstants.abn]: Yup.string().when([WaFormConstants.entityType], value => {
			if (value === 'company') {
				return Yup.string().required('ABN is required').length(11, "ABN must be 11 digits long").matches(/^\d+$/, "ABN must be digits only");
			} else {
				Yup.string().optional();
			}
		}),
		[WaFormConstants.companyAddress]: Yup.string().when([WaFormConstants.entityType], value => {
			if (value === 'company') {
				return Yup.string().required('Address is required');
			} else {
				Yup.string().optional();
			}
		}),
		[WaFormConstants.companySuburb]: Yup.string().when([WaFormConstants.entityType], value => {
			if (value === 'company') {
				return Yup.string().required('Suburb is required');
			} else {
				Yup.string().optional();
			}
		}),
		[WaFormConstants.companyState]: Yup.string().when([WaFormConstants.entityType], value => {
			if (value === 'company') {
				return Yup.string().required('State is required');
			} else {
				Yup.string().optional();
			}
		}),
		[WaFormConstants.companyPostcode]: Yup.string().when([WaFormConstants.entityType], value => {
			if (value === 'company') {
				return Yup.string()
					.required('Postcode is required')
					.when(['postcode'], {
						is: () => noRegexFor && noRegexFor.indexOf('postcode') > -1,
						then: Yup.string().max(40)
					})
					.when(['postcode'], {
						is: () => !noRegexFor || noRegexFor.indexOf('postcode') < 0,
						then: Yup.string()
							.matches(formFieldRex.postcodeRegExp, fieldErrorMessage.postcodeError)
							.max(findDonorByEmail ? 10 : 4)
					});
			} else {
				Yup.string().optional();
			}
		}),
		[WaFormConstants.contactFirstName]: Yup.string().when([WaFormConstants.entityType], value => {
			if (value === 'company') {
				return Yup.string().required('First name is required');
			} else {
				Yup.string().optional();
			}
		}),
		[WaFormConstants.contactLastName]: Yup.string().when([WaFormConstants.entityType], value => {
			if (value === 'company') {
				return Yup.string().required('Last name is required');
			} else {
				Yup.string().optional();
			}
		}),
		[WaFormConstants.contactEmail]: Yup.string().when([WaFormConstants.entityType], value => {
			if (value === 'company') {
				return Yup.string().required('Email is required').email(fieldErrorMessage.emailError).max(40);
			} else {
				Yup.string().optional();
			}
		}),
		[WaFormConstants.contactPhone]: Yup.string().when([WaFormConstants.entityType], value => {
			if (value === 'company') {
				return Yup.string()
					.required('Phone number is required')
					.matches(formFieldRex.phoneRegExp, fieldErrorMessage.phoneFieldError)
					.max(40);
			} else {
				Yup.string().optional();
			}
		})
	});

	if (donationDeclaration) {
		validationSchema[FormConstants.donationDeclaration] = Yup.bool().required('You must tick this box to continue');
	}

	customFields?.forEach((field: IFieldProps) => {
		if (field.Required) {
			if (field.Type !== 'Email address' && field.Type !== 'Phone number') {
				validationSchema = AddFieldValidation(field, validationSchema) as Yup.ObjectSchema<Yup.Shape<object, { [x: string]: any }>>;
			}
		}
		if (field.Type === 'Email address') {
			if (field.Required) {
				validationSchema = validationSchema.shape({
					[removeWhiteSpaces(field.Name)]: Yup.string().email(fieldErrorMessage.emailError).required(`${field.Name} required`)
				});
			} else {
				validationSchema = validationSchema.shape({
					[removeWhiteSpaces(field.Name)]: Yup.string().email(fieldErrorMessage.emailError)
				});
			}
		}
		if (field.Type === 'Phone number') {
			if (field.Required) {
				validationSchema = validationSchema.shape({
					[removeWhiteSpaces(field.Name)]: Yup.string()
						.matches(formFieldRex.phoneRegExp, fieldErrorMessage.phoneFieldError)
						.required(`${field.Name} required`)
				});
			} else {
				validationSchema = validationSchema.shape({
					[removeWhiteSpaces(field.Name)]: Yup.string().matches(formFieldRex.phoneRegExp, fieldErrorMessage.phoneFieldError)
				});
			}
		}
	});
	return validationSchema;
};

const valuesQstringMap = {
	FirstName: 'fn',
	LastName: 'ln',
	Email: 'em',
	Mobile: 'mb',
	Postcode: 'pc',
	Address: 'ad',
	State: 'st',
	Suburb: 'su'
};

export const convertValuesToQstring = (values: GenericFormModel) => {
	let qString = '';
	Object.entries(valuesQstringMap).forEach(([key, value], i) => {
		if (values[key] !== undefined && values[key] !== '') {
			qString = qString + `${i !== 0 ? '&' : '?'}` + `${value}=${values[key]}`;
		}
	});
	return qString;
};
